import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'features/common/MaterialBasedComponents';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import validation from '../../utils/validation';
import { folderModalActions } from './enums';
import T from 'i18n';
import InfoRow from 'features/common/InfoRow';
import moment from 'moment';
import { useGetMoreInfoFolder } from './redux/hooks';
import Spinner from 'features/common/Spinner';
import { SelectForm, TextForm, ToggleForm } from 'features/common';
import { useSelector } from 'react-redux';
import {
  selectContainers,
  selectCurrentCaseTeamId,
  selectCustomContainers,
  selectFolderContainers,
  selectGlobalPagingTrialbooks,
  selectIsAdmin,
  selectPermissions,
} from 'common/selectors';
import { formatLabel } from 'utils/formatLabel';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import { CollapsibleActivityLogs } from 'features/common/ActivityLogs';
import { findIndexOfDeepNestedFolder } from 'utils/arrays';
import FilterProvider from './TableCase/FilterProvider';
import HealthCheckModal from './HealthCheckModal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Grid } from '@mui/material';
import { useGetFolderStats } from './redux/getFolderStats';
import { bytesToMegaBytes } from 'utils/formating';
import debounce from 'utils/debounce';
import { useDispatch } from 'react-redux';
import { fetchGroups } from './redux/fetchGroups';
import RepositionModal from './RepositionModal';

const getTitles = (action) => {
  switch (action) {
    case folderModalActions.moveToTrialBooks:
      return {
        title: 'case.moveABundleTitle',
        saveTitle: 'generic.move',
        message: 'case.moveABundleMessage',
        containerType: 'bundle',
        toContainerName: 'Trial Bundles',
      };
    case folderModalActions.removeFromTrialBooks:
      return {
        title: 'case.removeABundleTitle',
        saveTitle: 'generic.remove',
        message: 'case.removeABundleMessage',
        containerType: 'bundle',
        toContainerName: 'Trial Bundles',
      };
    case folderModalActions.moveToCourtBundles:
      return {
        title: 'case.moveABundleTitle',
        saveTitle: 'generic.move',
        message: 'case.moveABundleMessage',
        containerType: 'bundle',
        toContainerName: 'Court Bundles',
      };
    case folderModalActions.removeFromCourtBundles:
      return {
        title: 'case.removeABundleTitle',
        saveTitle: 'generic.remove',
        message: 'case.removeABundleMessage',
        containerType: 'bundle',
        toContainerName: 'Court Bundles',
      };
    case folderModalActions.updateBundle:
      return {
        title: 'case.updateABundleTitle',
        saveTitle: 'generic.update',
        containerType: 'bundle',
      };
    case folderModalActions.createPrivateBundle:
    case folderModalActions.createTeamBundle:
    case folderModalActions.createBundle:
      return {
        title: 'case.addABundleTitle',
        saveTitle: 'generic.create',
        containerType: 'bundle',
      };
    case folderModalActions.deleteBundle:
      return {
        title: 'case.deleteABundleTitle',
        saveTitle: 'generic.delete',
        message: 'case.deleteABundleMessage',
        containerType: 'bundle',
      };
    case folderModalActions.share:
      return {
        title: 'case.shareABundleTitle',
        saveTitle: 'generic.share',
        message: 'case.shareABundleMessage',
        containerType: 'bundle',
      };
    case folderModalActions.createTrialbook:
      return {
        title: 'case.addATrialbookTitle',
        saveTitle: 'generic.create',
        containerType: 'trialbook',
      };
    case folderModalActions.agreeTrialbook:
      return {
        title: 'case.agreeTrialbookTitle',
        saveTitle: 'generic.confirm',
        message: 'case.agreeTrialbookMessage',
        containerType: 'trialbook',
      };
    case folderModalActions.updateTrialbook:
      return {
        title: 'case.updateTrialbook',
        saveTitle: 'generic.update',
        containerType: 'trialbook',
      };
    case folderModalActions.deleteTrialbook:
      return {
        title: 'case.deleteTrialbook',
        saveTitle: 'generic.delete',
        message: 'case.deleteTrialbookMessage',
        containerType: 'trialbook',
      };
    case folderModalActions.showHistory:
      return {
        title: 'case.showHistory',
        message: 'case.showHistoryMessage',
      };
    case folderModalActions.createTimeline:
      return {
        title: 'case.createTimeline',
        saveTitle: 'generic.create',
        containerType: 'timeline',
      };
    case folderModalActions.updateTimeline:
      return {
        title: 'generic.update',
        saveTitle: 'generic.update',
        containerType: 'timeline',
      };
    case folderModalActions.deleteTimeline:
      return {
        title: 'generic.delete',
        saveTitle: 'generic.delete',
        message: 'case.deleteTimelineMessage',
        containerType: 'timeline',
      };
    case folderModalActions.createTag:
      return {
        title: 'generic.createTag',
        saveTitle: 'generic.create',
        containerType: 'tags',
      };
    case folderModalActions.updateTag:
      return {
        title: 'generic.update',
        saveTitle: 'generic.update',
        containerType: 'tags',
      };
    case folderModalActions.deleteTag:
      return {
        title: 'generic.delete',
        saveTitle: 'generic.delete',
        message: 'case.deleteTagMessage',
        containerType: 'tag',
      };
    case folderModalActions.burnCrossRef:
      return {
        title: 'case.saveCrossRefMsg',
        saveTitle: 'generic.burn',
        message: 'case.burnCrossRefMessage',
      };
    case folderModalActions.reIndex:
      return {
        title: 'generic.reIndex',
        saveTitle: 'generic.reIndex',
        message: 'case.reIndexMessage',
      };
    case folderModalActions.healthCheck:
      return {
        title: 'generic.healthCheckTitle',
      };
    case folderModalActions.exportTimeline:
      return {
        title: 'case.exportTimeline',
        saveTitle: 'generic.export',
        message: 'case.exportTimelineMessage',
      };
    case folderModalActions.exportFolder:
      return {
        title: 'case.exportFolder',
        saveTitle: 'generic.export',
        message: 'case.exportFolderMessage',
      };
    case folderModalActions.downloadFolder:
      return {
        title: 'case.downloadFolder',
        saveTitle: 'generic.download',
        message: 'case.downloadFolderMessage',
      };
    case folderModalActions.deleteAll:
      return {
        title: 'case.deleteAllTitle',
        saveTitle: 'generic.delete',
        message: 'case.deleteAllMessage',
      };
    case folderModalActions.clone:
      return {
        title: 'case.clone',
        saveTitle: 'generic.clone',
        message: '',
      };
    case folderModalActions.convertToBundle:
      return {
        title: 'case.convertToBundle',
        saveTitle: 'case.convertToBundle',
        message: '',
      };
    case folderModalActions.unlock:
      return {
        title: 'case.unlock',
        saveTitle: 'case.unlock',
        message: '',
      };
    case folderModalActions.shareWithGroup:
      return {
        title: 'case.moveToGroupBundles',
        saveTitle: 'generic.move',
        message: 'case.shareWithGroupMessage',
      };
    case folderModalActions.removeFromGroupBundles:
      return {
        title: 'case.removeFromGroupBundles',
        saveTitle: 'generic.remove',
        message: 'case.removeFromGroupBundlesMessage',
        containerName: 'Group Bundles',
      };
    default:
      return {};
  }
};

const HistoryInfo = ({
  createdBy,
  lastEditedBy,
  publishedBy,
  pending,
  data,
  fileCount = 0,
  folder,
}) => {
  const { folderStats, getFolderStats, getFolderStatsPending } = useGetFolderStats();
  const [loading, setLoading] = useState(getFolderStatsPending);
  useEffect(() => {
    getFolderStats({ folderId: folder.id });
  }, [folder, getFolderStats]);

  useEffect(() => {
    if (getFolderStatsPending) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getFolderStatsPending]);

  return (
    <div style={{ padding: '2rem', paddingBottom: 0 }}>
      {createdBy && (
        <>
          <InfoRow field={createdBy.name} title={'case.createdByName'} />
          <InfoRow
            field={moment(createdBy.date).utc().format('DD/MM/YYYY HH:mm')}
            title={'case.createdByDate'}
          />
        </>
      )}
      {lastEditedBy && (
        <>
          <InfoRow field={lastEditedBy.name} title={'case.lastEditedByName'} />
          <InfoRow
            field={moment(lastEditedBy.date).utc().format('DD/MM/YYYY HH:mm')}
            title={'case.lastEditedByDate'}
          />
        </>
      )}
      {!loading ? (
        <>
          <InfoRow field={`${folderStats?.fileCount}`} title={'case.historyModal.totalFiles'} />

          <InfoRow
            field={`${bytesToMegaBytes(folderStats?.totalFileSize)}`}
            title={'case.historyModal.totalSize'}
          />

          <InfoRow field={`${folderStats?.pageCount || 0}`} title={'case.historyModal.pageCount'} />

          <InfoRow
            field={`${folderStats?.subFolderCount || 0}`}
            title={'case.historyModal.subFolderCount'}
          />
        </>
      ) : (
        <Spinner style={{ fontSize: '0.5rem' }} />
      )}
      {pending ? (
        <Spinner style={{ fontSize: '0.5rem' }} />
      ) : (
        <CollapsibleActivityLogs
          data={data}
          tableHeight={'25rem'}
          rowHeight={70}
          outsideTableMargin={'0'}
        />
      )}
    </div>
  );
};

const mapping = {
  trialbook: 'trialbooks',
  'court-bundle': 'court-bundles',
  bundle: 'bundles',
  'team-bundle': 'teamBundles',
  'private-bundle': 'privateBundles',
};

const checkGPStatusofParentFolder = (folder, containers) => {
  //checking for only level 1 as GP is allowd at level 0 & 1
  if (
    folder &&
    folder.level === 1 &&
    folder.type !== 'tags' &&
    folder.type !== 'annotations' &&
    folder.type !== 'timelines'
  ) {
    const folderContainer = containers.find((cnt) => cnt.code === mapping[folder.type]);
    const parentFolders = folderContainer['folders'];
    for (let index = 0; index < parentFolders.length; index++) {
      if (findIndexOfDeepNestedFolder(parentFolders[index]['folders'], folder) >= 0) {
        return parentFolders[index].globalPaging;
      }
    }
  }
  return false;
};

const checkGpStatusOfChildFolders = (folders) => {
  if (folders && folders.length > 0) {
    for (let index = 0; index < folders.length; index++) {
      if (folders[index].globalPaging) return true;
    }
  }
  return false;
};

export default function FolderModal({
  show,
  handleClose,
  handleSave,
  action,
  folder,
  isBundleTabPage,
  trialBooksData,
  bundles,
  code,
}) {
  const globalPagingTrialbooks = useSelector(selectGlobalPagingTrialbooks);
  const {
    folders,
    zeroBasedIndex = false,
    generateIndexFolder = false,
    globalPaging = globalPagingTrialbooks,
    globalPagePrefix = '',
    predictiveIndex = false,
    agreed = false,
  } = folder || {};
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const dispatch = useDispatch();
  const titles = getTitles(action);
  const { moreInfoFolder, getMoreInfoFolder, getMoreInfoFolderPending } = useGetMoreInfoFolder();
  const isCustomContainers = useSelector(selectCustomContainers);
  const containers = useSelector(selectContainers);
  const folderContainers = useSelector(selectFolderContainers);
  const { documents: { canAgreeTrialbook, canUnAgreeTrialbook } = {} } =
    useSelector(selectPermissions);

  const isSystemAdmin = useSelector(selectIsAdmin);
  const teamId = useSelector(selectCurrentCaseTeamId);

  const state = useSelector((state) => state);
  const {
    folders: { canAddDocuments },
  } = selectPermissions(state);
  const trialbooks = (state.case.folders.trialbooks || []).filter((f) => canAddDocuments(f));
  const teamBundles = (state.case.folders.teamBundles || []).filter((f) => canAddDocuments(f));
  const privateBundles = (state.case.folders.privateBundles || []).filter((f) =>
    canAddDocuments(f),
  );

  const folderCreatedByTeamId = folder && folder.createdBy && folder.createdBy.teamId;
  const folderCreatedByTeamName = folder && folder.createdBy && folder.createdBy.teamName;
  const isAgreeTrialbook =
    (canAgreeTrialbook || isSystemAdmin) && folder && folder.type === 'trialbook';
  const isUnAgreeTrialbook = canUnAgreeTrialbook;
  const isGPEnabledForParentFolders = checkGPStatusofParentFolder(folder, folderContainers);
  const isGPEnabledForChildFolders = checkGpStatusOfChildFolders(folders);
  const enableGP =
    folder && folder.level === 0
      ? !isGPEnabledForChildFolders || (isGPEnabledForChildFolders && folder.globalPaging)
      : folder && folder.level === 1
        ? !isGPEnabledForParentFolders
        : true;

  const [showGlobalPaging, setShowGlobalPaging] = useState(globalPaging);
  const [globalPrefixVal, setGlobalPrefixVal] = useState(globalPagePrefix || '');
  const [showWarning, setShowWarning] = useState(false);
  const [groupOptions, setGroupOptions] = useState(null);
  const [cloneFolderType, setCloneFolderType] = useState('');
  const [duplicatePrefixError, setDuplicatePrefixError] = useState('');

  const checkDuplicatePrefix = useCallback(
    debounce((prefix) => {
      // If prefix is empty, no need to check for duplicates
      if (!prefix) {
        setDuplicatePrefixError('');
        return;
      }

      // Check if current folder is being edited (to exclude its own prefix)
      const currentFolderId = folder?.id;

      // Check if prefix already exists in other bundles
      const duplicateFound =
        trialBooksData &&
        trialBooksData.some(
          (bundle) =>
            // Skip comparing with current bundle if we're editing
            bundle.id !== currentFolderId &&
            bundle.globalPagePrefix &&
            bundle.globalPagePrefix.toUpperCase() === prefix.toUpperCase(),
        );

      if (duplicateFound) {
        setDuplicatePrefixError(T.translate('case.duplicatePrefixErrorMessage'));
      } else {
        setDuplicatePrefixError('');
      }
    }, 200), // 500ms debounce delay
    [trialBooksData, folder],
  );

  const getBooks = (books) =>
    books.map((book) => {
      if (book.folders && book.folders.length > 0) {
        return {
          label: book.name,
          options: getBooks(book.folders),
        };
      } else {
        return book;
      }
    });

  const getCloneFolderToOptions = (container) => {
    if (container === 'trialbook' && trialbooks.length > 0) {
      return [
        {
          label: 'Trialbooks',
          options: getBooks(trialbooks),
        },
      ];
    } else if (container === 'team-bundle' && teamBundles.length > 0) {
      return [
        {
          label: 'Team Bundles',
          options: getBooks(teamBundles),
        },
      ];
    } else if (container === 'private-bundle' && privateBundles.length > 0) {
      return [
        {
          label: 'Private Bundles',
          options: getBooks(privateBundles),
        },
      ];
    } else return [];
  };

  const cloneFolderToOptions = getCloneFolderToOptions(cloneFolderType);

  const handleWarning = (target) => {
    if (target) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  useEffect(() => {
    if (action === folderModalActions.shareWithGroup && !groupOptions) {
      dispatch(fetchGroups()).then((res) => {
        setGroupOptions(res.map((g) => ({ name: g.name, id: g.id })));
      });
    }
  }, [action, groupOptions, dispatch, teamId]);

  useEffect(() => {
    if (action === folderModalActions.showHistory && show) {
      getMoreInfoFolder({ folderId: folder.id });
    }
  }, [action, getMoreInfoFolder, show, folder]);

  const formLabelHandler = () => {
    if (action === folderModalActions.clone) {
      return T.translate('case.cloneFolderName');
    } else if (action === folderModalActions.convertToBundle) {
      return T.translate('case.convertBundleName');
    } else {
      return T.translate('case.bundleName');
    }
  };

  const formPlaceholderHandler = () => {
    if (action === folderModalActions.createTrialbook) {
      return T.translate('case.trialbookPlaceholder');
    } else if (
      action === folderModalActions.createTimeline ||
      action === folderModalActions.updateTimeline
    ) {
      return T.translate('case.timelinePlaceholder');
    } else if (action === folderModalActions.createTag || action === folderModalActions.updateTag) {
      return T.translate('case.createTagPlaceholder');
    }

    return T.translate('case.bundlePlaceholder');
  };

  const handleGlobalPrefixChange = (e) => {
    let value = e.target.value;

    // Always convert to uppercase
    value = value.toUpperCase();

    // If empty, allow it
    if (value === '') {
      setGlobalPrefixVal('');
      checkDuplicatePrefix(''); // Clear any duplicate errors
      return;
    }

    // Apply different filtering rules based on isBundleTabPage
    let filteredValue;

    if (isBundleTabPage) {
      // For bundle tab page:
      // Allow only alphanumeric characters and dots
      filteredValue = value.replace(/[^A-Z0-9.]/g, '');

      // Check if first character is a letter (not a number or dot)
      if (filteredValue.length > 0 && !/^[A-Z]/.test(filteredValue)) {
        return; // Don't update if doesn't start with a letter
      }

      // Don't allow three or more consecutive letters (e.g., "AAA")
      if (/[A-Z]{3,}/.test(filteredValue)) {
        return; // Reject if 3 or more consecutive letters anywhere
      }

      // Don't allow a dot as the first character
      if (filteredValue.length === 1 && filteredValue === '.') {
        return;
      }

      // Don't allow consecutive dots
      if (
        filteredValue.endsWith('.') &&
        filteredValue.length > 1 &&
        filteredValue[filteredValue.length - 2] === '.'
      ) {
        return;
      }

      // Define the valid patterns explicitly:

      // 1. Single letter (A, B)
      const singleLetterPattern = /^[A-Z]$/;

      // 2. Double letters (AA, BB)
      const doubleLetterPattern = /^[A-Z]{2}$/;

      // 3. Single letter + 1-2 digits (A1, A99)
      const letterDigitPattern = /^[A-Z][0-9]{1,2}$/;

      // 4. Single letter + 1-2 digits + dot + 1-2 digits (A1.1, A99.99, A9.99, A99.9)
      const letterDigitDotDigitPattern = /^[A-Z][0-9]{1,2}\.[0-9]{1,2}$/;

      // Important: Do NOT allow AA1 pattern (2 letters + digit)
      // Adding explicit check to reject AA1 pattern
      if (/^[A-Z]{2}[0-9]/.test(filteredValue)) {
        return; // Reject patterns like AA1
      }

      // Check if the current value matches any of the valid patterns
      const isValidPattern =
        singleLetterPattern.test(filteredValue) ||
        doubleLetterPattern.test(filteredValue) ||
        letterDigitPattern.test(filteredValue) ||
        letterDigitDotDigitPattern.test(filteredValue);

      // Allow partial inputs that could be valid when completed
      const isIncompleteValidPattern =
        /^[A-Z]{1,2}$/.test(filteredValue) || // Partial letters (A, AA)
        /^[A-Z][0-9]{1,2}$/.test(filteredValue) || // Single letter + 1-2 digits (A1, A99)
        /^[A-Z][0-9]{1,2}\.?$/.test(filteredValue) || // Partial dot format (A1., A99.)
        /^[A-Z][0-9]{1,2}\.[0-9]{1}$/.test(filteredValue); // Single digit after dot (A1.1, A99.9)

      // Don't allow formats like AA.1 (two letters followed by dot)
      if (/^[A-Z]{2}\./.test(filteredValue)) {
        return;
      }

      // Reject the input if it doesn't match any valid pattern or incomplete pattern
      if (!isValidPattern && !isIncompleteValidPattern) {
        return;
      }
    } else {
      // For non-bundle tab page:
      // Allow only alphabetic characters (no dots or numbers)
      filteredValue = value.replace(/[^A-Z]/g, '');

      // Don't allow three or more letters
      if (filteredValue.length > 2) {
        return; // Don't update if more than 2 letters for non-bundle
      }
    }

    // Don't allow input to end with a dot
    if (filteredValue.endsWith('.') && !/^[A-Z][0-9]{1,2}\.$/.test(filteredValue)) {
      // Only allow the dot if it's in the pattern A1. or A99.
      return;
    }

    setGlobalPrefixVal(filteredValue);

    // Check for duplicates with debounce
    checkDuplicatePrefix(filteredValue);
  };
  return (
    <>
      <Modal
        show={show}
        title={T.translate(titles.title, {
          containerType: isCustomContainers
            ? 'bundle'
            : titles.containerType === 'trialbook'
              ? 'trial bundle'
              : titles.containerType,
          ...((action === folderModalActions.healthCheck ||
            action === folderModalActions.clone ||
            folderModalActions.reposition) &&
            folder && { folderName: folder.name }),
        })}
        handleClose={() => {
          setShowGlobalPaging(globalPaging);
          setGlobalPrefixVal(globalPagePrefix);
          handleClose();
        }}
        handleSave={
          titles.saveTitle &&
          handleSubmit((data) => {
            handleSave(data, action, folder);
            handleClose();
          })
        }
        saveTitle={T.translate(titles.saveTitle)}
        disableSave={!!duplicatePrefixError}
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          hidden={
            ![
              folderModalActions.createTeamBundle,
              folderModalActions.createPrivateBundle,
              folderModalActions.createBundle,
              folderModalActions.updateBundle,
              folderModalActions.createTrialbook,
              folderModalActions.updateTrialbook,
              folderModalActions.createTimeline,
              folderModalActions.updateTimeline,
              folderModalActions.createTag,
              folderModalActions.updateTag,
              folderModalActions.reIndex,
              folderModalActions.clone,
              folderModalActions.convertToBundle,
              folderModalActions.shareWithGroup,
            ].includes(action)
          }
        >
          <Form.Group style={{ margin: '0.5rem' }}>
            {action !== folderModalActions.reIndex &&
              action !== folderModalActions.shareWithGroup && (
                <Row>
                  <Col>
                    <TextForm
                      {...register('name', { required: true, pattern: validation.folder })}
                      label={formLabelHandler()}
                      placeholder={formPlaceholderHandler()}
                      defaultValue={folder && folder.name}
                      readOnly={[
                        folderModalActions.agreeTrialbook,
                        folderModalActions.deleteTrialbook,
                        folderModalActions.deleteBundle,
                        folderModalActions.deleteAll,
                        folderModalActions.share,
                        folderModalActions.deleteTimeline,
                        folderModalActions.deleteTag,
                        folderModalActions.burnCrossRef,
                        folderModalActions.reIndex,
                      ].includes(action)}
                      errors={errors}
                      autofocus
                    />
                  </Col>
                </Row>
              )}
            {folder &&
              (folder.level === 0 || folder.level === 1) &&
              (action === folderModalActions.updateTrialbook ||
                action === folderModalActions.updateBundle) && (
                <>
                  {isAgreeTrialbook &&
                    folder &&
                    (folder.level === 0 || folder.level === 1) &&
                    !folder.readOnly && (
                      <Row>
                        <Col>
                          <ToggleForm
                            {...register('agreed', {
                              onChange: (e) => handleWarning(e.target.checked),
                            })}
                            label={T.translate('case.agreeTrialbook')}
                            defaultValue={agreed}
                            disabled={agreed && !isUnAgreeTrialbook}
                          />
                        </Col>
                        <Col />
                        {showWarning && (
                          <span className="agree-warning">
                            {T.translate('case.agreeTrialbookWarning')}
                          </span>
                        )}
                      </Row>
                    )}
                  <Row>
                    <Col>
                      <ToggleForm
                        {...register('predictiveIndex')}
                        label={T.translate('case.predictiveIndex')}
                        defaultValue={predictiveIndex}
                      />
                    </Col>
                    <Col />
                  </Row>
                  {folder && folder.folders && folder.folders.length > 0 && (
                    <Row>
                      <Col>
                        <ToggleForm
                          {...register('generateIndexFolder')}
                          label={T.translate('case.generateIndexFolder')}
                          defaultValue={generateIndexFolder}
                        />
                      </Col>
                      <Col />
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <ToggleForm
                        {...register('zeroBasedIndex')}
                        label={T.translate('case.zeroBasedIndex')}
                        defaultValue={zeroBasedIndex}
                      />
                    </Col>
                    <Col />
                  </Row>
                  <Row>
                    <Col>
                      <ToggleForm
                        {...register('globalPaging', {
                          onChange: () => setShowGlobalPaging(!showGlobalPaging),
                        })}
                        label={T.translate('generic.globalPaging')}
                        defaultValue={showGlobalPaging}
                        disabled={!enableGP}
                      />
                    </Col>
                    <Col />
                  </Row>
                  {showGlobalPaging && (
                    <Row>
                      <Col>
                        <TextForm
                          {...register('globalPagePrefix', {
                            onChange: handleGlobalPrefixChange,
                          })}
                          label={T.translate('case.globalPagePrefix')}
                          value={globalPrefixVal}
                          disabled={!enableGP}
                        />
                        {duplicatePrefixError && (
                          <div style={{ color: 'red', marginTop: '5px', fontSize: '0.875rem' }}>
                            {duplicatePrefixError}
                          </div>
                        )}
                      </Col>
                      <Col />
                      <Col />
                    </Row>
                  )}
                  <Row>
                    {!enableGP && (
                      <Col>
                        {folder.level === 0
                          ? T.translate('case.diableGPFieldMsgLevel0')
                          : T.translate('case.diableGPFieldMsgLevel1')}
                      </Col>
                    )}
                  </Row>
                </>
              )}
            {action === folderModalActions.reIndex && (
              <div>
                <span>{T.translate('case.reIndexTitleMessage')}</span>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                  <Grid item xs={6}>
                    <ToggleForm
                      {...register('reindexExtractEntities')}
                      defaultValue={false}
                      label={T.translate(`case.reindexExtractEntities`)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ToggleForm
                      {...register('reindexExtractDates')}
                      defaultValue={false}
                      label={T.translate(`case.reindexExtractDates`)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                  <Grid item xs={6}>
                    <ToggleForm
                      {...register('reindexGenerateJson')}
                      defaultValue={false}
                      label={T.translate(`case.reindexGenerateJson`)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ToggleForm
                      {...register('reindexUpdateSearchIndex')}
                      defaultValue={false}
                      label={T.translate(`case.reindexUpdateSearchIndex`)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                  <Grid item xs={6}>
                    <ToggleForm
                      {...register('reindexUpdatePinecone')}
                      defaultValue={false}
                      label={T.translate(`case.reindexUpdatePinecone`)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ToggleForm
                      {...register('failedEmbeddings')}
                      defaultValue={false}
                      label={T.translate(`case.failedEmbeddings`)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ToggleForm
                      {...register('clearCurrentJob')}
                      defaultValue={false}
                      label={T.translate(`case.clearCurrentJob`)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ToggleForm
                      {...register('reindexEmailConfidence')}
                      defaultValue={false}
                      label={T.translate(`case.reindexEmailConfidence`)}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            {action === folderModalActions.clone && (
              <>
                <Grid container>
                  <Grid item xs={6}>
                    <SelectForm
                      name="cloneType"
                      label={T.translate(`case.cloneOption`)}
                      options={[
                        {
                          label: T.translate(`case.cloneStructureOnly`),
                          value: 'structureonly',
                        },
                        {
                          label: T.translate(`case.cloneStructureAndFiles`),
                          value: 'filesAndStructure',
                        },
                        {
                          label: T.translate(`case.cloneFilesOnly`),
                          value: 'filesonly',
                        },
                      ]}
                      control={control}
                      errors={errors}
                      rules={{ required: true }}
                      menuPosition="fixed"
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: 5 }}>
                  <Grid item xs={6}>
                    <SelectForm
                      name="type"
                      onChange={(val) => setCloneFolderType(val)}
                      label={T.translate(`case.cloneFolderType`)}
                      options={[
                        // {
                        //   label: T.translate(`case.cloneIntoOptions.trialBundles`),
                        //   value: 'trialbook',
                        // },
                        {
                          label: T.translate(`case.cloneIntoOptions.teamBundles`),
                          value: 'team-bundle',
                        },
                        {
                          label: T.translate(`case.cloneIntoOptions.privateBundles`),
                          value: 'private-bundle',
                        },
                      ]}
                      control={control}
                      errors={errors}
                      rules={{ required: true }}
                      menuPosition="fixed"
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: 5 }}>
                  <Grid item xs={6}>
                    <SelectForm
                      name="parentFolderId"
                      label={T.translate(`case.cloneToFolder`)}
                      valueKey="id"
                      labelKey="name"
                      options={cloneFolderToOptions}
                      control={control}
                      errors={errors}
                      menuPosition="fixed"
                      readOnly={!cloneFolderType}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {action === folderModalActions.shareWithGroup && (
              <Grid container sx={{ mb: '1rem' }}>
                <Grid item xs={6}>
                  <SelectForm
                    name="groupId"
                    label={T.translate('case.selectGroup')}
                    valueKey="id"
                    labelKey="name"
                    //autofocus
                    defaultValue={''}
                    options={groupOptions || []}
                    control={control}
                    errors={errors}
                    rules={{ required: true }}
                    menuPosition="fixed"
                  />
                </Grid>
              </Grid>
            )}
          </Form.Group>
        </Form>
        <div>
          {T.translate(titles.message, {
            folder: folder && folder.name,
            containerType: isCustomContainers
              ? 'bundle'
              : titles.containerType === 'trialbook'
                ? 'trialbundle'
                : titles.containerType,
            ...((titles.message === 'case.moveABundleMessage' ||
              titles.message === 'case.removeABundleMessage') && {
              containerName:
                isCustomContainers && containers
                  ? titles.toContainerName === T.translate('case.courtBundles')
                    ? formatLabel(containers[1])
                    : formatLabel(containers[0])
                  : titles.toContainerName,
            }),
            ...(titles.message === 'case.removeFromGroupBundlesMessage' && {
              containerName: titles.containerName,
            }),
          })}
          {action === folderModalActions.showHistory && (
            <FilterProvider>
              <HistoryInfo
                {...folder}
                pending={getMoreInfoFolderPending}
                data={moreInfoFolder}
                folder={folder}
              />
            </FilterProvider>
          )}
        </div>
        {(action === folderModalActions.removeFromTrialBooks ||
          action === folderModalActions.removeFromCourtBundles) &&
          teamId !== folderCreatedByTeamId && (
            <span>
              {T.translate('case.removeFromBundleNote', {
                createdByTeamName: folderCreatedByTeamName,
              })}
            </span>
          )}
        {action === folderModalActions.healthCheck && (
          <div style={{ margin: '-1.5rem' }}>
            <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: '45rem' }}>
              <HealthCheckModal folderId={folder.id} />
            </PerfectScrollbar>
          </div>
        )}
      </Modal>
      {action === folderModalActions.reposition && !folder.readOnly && !folder.agreed && (
        <RepositionModal
          handleClose={handleClose}
          show={action === folderModalActions.reposition}
          folder={folder}
        />
      )}
    </>
  );
}

FolderModal.propTypes = {};
FolderModal.defaultProps = {};
